body {
    scroll-behavior: smooth;
    font-size: 17px;
}

@keyframes scroll {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}

.about-company {
    color: black;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    background-image: url("../assets/banner-photo.jpg");
    animation: scroll 60s linear infinite;
    background-size: cover;
    background-position: center;
    padding: 10px;
}

.mission-logo {
    width: 500px;
    height: 300px;
    object-fit: cover;
    border-radius: 50% 50%;
    box-shadow: 0px 0px 20px 4px #000000c9;
}

.mission {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mission-text {
    padding: 10px;
    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.5);
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 300px;*/
}

.about-company h1 {
    text-align: center;
}

.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
}

.team-card {
    width: calc(45% - 20px);
    margin-bottom: 20px;
    height: 350px;
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 35px;
    transition-duration: 0.7s;
}

.team-card:hover {
    box-shadow: 0px 0px 10px black;
}

.member-info {
    width: 50%;
    height: 100%;
}

.user-image {
    width: 45%;
    height: 100%;
    overflow: hidden;
    border-radius: 35px 0px 0px 35px;
}

.news {
    display: flex;
    justify-content: space-around;
}

.pag {
    display: flex;
    justify-content: space-around;
}

.news-container {
    background-image: url("../assets/news-banner.png");
    background-repeat: no-repeat;
    animation: scroll 30s linear infinite;
    background-size: cover;
    background-position: center;
}

.news-container h1 {
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.contact-container{
    background-color: #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
}

iframe {
    width: 85%;
    height: 300px;
}

.map {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.contact-general {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.working-hours {
    text-align: center;
    width: 40%;
}

.contact-info {
    width: 40%;
}

.footer {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f7941d;
    text-align: center;
}

.footer .company {
    margin-top: 0;
}

.footer .rights {
    margin-bottom: 0;
}

hr {
    margin: 0;
}

.work-container {
    background-color: #eeeeee;
    padding: 15px;
}

.work-container h1 {
    margin-top: 0;
}

.container {
    position: relative;
    width: 45%;
    height: 300px;
    margin: 10px;
}

.image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}

.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.container:hover .overlay {
    opacity: 1;
}

.text {
    color: white;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.sponsor-logo {
    width: 280px;
}

.sponsor {
    width: 40%;
    text-align: center;
}

.sponsors-info {
    display: flex;
    justify-content: space-around;
}

.user-image {
    object-fit: cover;
}

.statements {
    display: flex;
    justify-content: space-around;
}

.objectives, .values {
    width: 50%;
}

@media (max-width: 1024px) {
    .team-card {
        width: calc(50% - 20px);
    }

    .news-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 13px;
    }

    .text {
        position: relative;
    }

    .container {
        width: 80%;
    }

    .news {
        flex-direction: column;
        margin: auto;
    }
    .news-card {
        margin: 10px;
    }
}

@media (max-width: 768px) {
    body {
        font-size: 15px;
    }

    .team-card {
        width: 100%;
    }

    .mission {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .mission-logo {
        margin: auto;
        width: 300px;
    }

    .sponsors-info {
        flex-direction: column;
        margin: auto;
    }

    .sponsor {
        width: 80%;
        margin: auto;
    }

    .mission-text {
        text-align: center;
    }

    .container {
        width: 95%;
    }

    .statements {
        display: block;
    }

    .objectives, .values {
        width: 100%;
    }
}