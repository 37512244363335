@import url("//cdn.web-fonts.ge/fonts/alk-rounded-mtav-med/css/alk-rounded-mtav-med.min.css");
/*@import url('https://fonts.googleapis.com/css2?family=Sedan:ital@0;1&display=swap');*/

@font-face {
  font-family: AmericanTypewriter;
  src: url("./assets/typewriter.ttf");
}

.ka {
  font-family: "ALK Rounded Mtav Med", sans-serif !important;
}

.en {
  font-family: AmericanTypewriter;
}